import { createApp } from 'vue'
import App from './App.vue'

import VueApexCharts from "vue3-apexcharts";
import PowerGlitchPlugin from 'vue-powerglitch';
import atropos from 'atropos';
import FloatingVue from 'floating-vue'
import ToastPlugin from 'vue-toast-notification';

import 'floating-vue/dist/style.css'

createApp(App)
    .use(VueApexCharts)
    .use(PowerGlitchPlugin)
    .use(atropos)
    .use(FloatingVue)
    .use(ToastPlugin)
    .mount('#app')