// PURPOSE: Check if API is online / still retrieving any data
import { GetAPILink } from './variables.js';
import axios from 'axios';
import { ShowToast } from './toast.js';


let online = false;
let check_instance = null;

// --- [ API CHECK ] --- //
export async function CheckAPI() {
    const api = GetAPILink();
    try {
        check_instance = ShowToast("Booting API Online", "warning", 9999999999999);
        const res = await axios.get(api);
        if (res.status === 200) {
            online = true;
            check_instance.dismiss();
            return true;
        }
    } catch (error) {
        console.log("ERROR:", error);
        return false;
    }
}

export function IsOnline() {
    return online;
}