<template lang="en">
    <div style="width:100%">
        <h2>Experience</h2>
        <p>Job Experinence & Public Projects</p>

        <div class="container">
            <div class="half">
                <section v-if="work" v-for="item in work">
                    <div class="edu-card hover">
                        <div class="edu-content">
                            <h4>{{ item.Company }}</h4>
                            <div class="indent subtle">
                                <p>{{ item.Role.value }}</p>
                                <p>{{ item.Type.value }}</p>
                                <p>{{ item.Duration.start }} <span v-if="item.Duration.end">- {{ item.Duration.end }}</span></p>
                            </div>
                            <div class="indent">
                                <p><span class="highlight">Skills:</span> {{ item.Skills.value }}</p>
                            </div>
                        </div>

                        <!-- <section class="edu-image" v-bind:style="{ 'background-image': 'url(' + item.Image.files[0].file.url + ')' }"></section> -->
                    </div>
                </section>
            </div>

            <!-- Content -->
            <div class="half projects">
                <section v-if="!mobile" v-for="project in projects" :key="project.id" class="mobile-hidden">
                    <!-- <atropos-component highlight=false> -->
                        <div class="project-card hover" data-atropos-offset="0" >
                            <div class="project-container">
                                <div>
                                    <h4 data-atropos-offset="1"> {{ project.name }} </h4>
                                    <p class="subtle" data-atropos-offset="1"> {{ project.description }} </p>
                                </div>
                                <div class="project-footer" data-atropos-offset="2">
                                    <i :class="`devicon-${project.language_image}-plain`" class="language-icon" v-tooltip="project.language"></i>
                                    <a :href="project.html_url" target="_blank"><img  width="30" src="https://img.icons8.com/material-outlined/60/FFFFFF/github.png" v-tooltip="'View On GitHub'"/></a>
                                </div>
                            </div>
                        </div>
                    <!-- </atropos-component> -->
                </section>
                <div v-else class="landing-links center" style="width:100%">
                    <a href="https://github.com/NotAddison" class="hover" target="_blank">
                        <p class="subtle">Click Me To View Public Projects</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetGitProjects, GetWorkExperience } from "@/functions/experience";
import { GetGlitchSettings } from "@/functions/variables";

export default {
    data: () => ({
        work: [],
        projects: [],
        glitchOption: GetGlitchSettings(),
    }),
    created() {
        GetGitProjects().then((data) => {
            this.projects = data;
        });

        GetWorkExperience().then((data) => {
            this.work = data;
        });
    },
    computed: {
        mobile() {
            return window.innerWidth < 768 ? true : false;
        },
    },
};
</script>

<style lang="css">
.projects {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 1rem;
    height: 50vh;
    overflow: scroll;
}

.project-card {
    padding: 1rem;
    width: 18vw;
    height: 10.5vh;

    --b: 2px; /* thickness of the border */
    --c: rgba(233, 233, 233, 0.103); /* color of the border */
    --w: 20px; /* width of border */
    border: var(--b) solid #0000; /* space for the border */
    --_g: #0000 90deg, var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    background: conic-gradient(
                from 90deg at top var(--b) left var(--b),
                var(--_g)
            )
            0 0 / var(--_p),
        conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g))
            100% 0 / var(--_p),
        conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0
            100% / var(--_p),
        conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
            100% 100% / var(--_p);

    background-color: rgba(3, 3, 3, 0.37);
}

.project-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;
}

.project-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* Set project's language icon size */
.project-card .language-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

/* Mobile */
@media (max-width: 768px) {
    .projects {
        justify-content: center;
    }

    .project-card {
        width: 100%;
    }
}
</style>
