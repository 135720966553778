import axios from "axios";

// Export Functions
export { GetWakaData, ImageMap, LanguageSiteMap, exclude_filter }

// -- [ Global Vars ] --
let total_hours;
let readable_hours;
let filter_hours = 2;
let data;

// -- [ Language Filter ] --
const exclude_filter = [
    'CSHTML',
    'JSON',
    'Markdown',
    'Text',
    'Other',
    'XML',
    'YAML',
    'VB.NET',
    'PHP',
    'Groovy',
    'Batchfile',
    'Git Config'
];

// -- [ Language Combine ] --
const combine_list = [
    ["GDScript3", "GDScript", "Godot"],
]

// -- [ Language Docs ] --
const languageMap = {
    "csharp": "https://docs.microsoft.com/en-us/dotnet/csharp/",
    "cplusplus": "https://www.cplusplus.com/",
    "css3": "https://developer.mozilla.org/en-US/docs/Web/CSS",
    "html5": "https://developer.mozilla.org/en-US/docs/Web/HTML",
    "sqlite": "https://www.sqlite.org/index.html",
    "javascript": "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    "python": "https://www.python.org/",
    "arduino": "https://www.arduino.cc/",
    "c": "https://en.wikipedia.org/wiki/C_(programming_language)",
    "java": "https://www.java.com/en/",
    "typescript": "https://www.typescriptlang.org/",
    "react": "https://reactjs.org/",
    "flutter": "https://flutter.dev/",
    "dart": "https://dart.dev/",
    "assembly": "https://en.wikipedia.org/wiki/Assembly_language",
    "mysql": "https://www.mysql.com/",
    "vue.js": "https://vuejs.org/",
    "cpp": "https://www.cplusplus.com/",
};

async function GetWakaData() {
    // -- [ GET : WakaTime Total Hours ] --
    try {
        const res = await axios.get('https://wakatime.com/share/@NotAddison/65aa1aab-26b1-463a-b439-dafe29a5fc88.json');
        total_hours = res.data.data.grand_total.total_seconds;
        readable_hours = res.data.data.grand_total.human_readable_total_including_other_language;
    }
    catch (error) { console.log(error) }


    // -- [ GET : WakaTime Programming Language Stats ] --
    try {
        const res = await axios.get('https://wakatime.com/share/@NotAddison/afd7c26c-62f0-48a2-86cb-c098edd4654b.json');
        data = ParseStats(res, total_hours)
    }
    catch (error) { console.log(error) }

    return {
        total_hours: total_hours,
        readable_hours: readable_hours,
        filter_hours: filter_hours,
        data: data
    }
}

// -- [ PARSE Wakatime Statistics : % -> Hours : Filter ] --
function ParseStats(data, total_hours) {
    data = data.data.data;
    data.forEach(obj => {
        obj.hours = ((obj.percent / 100) * (total_hours / 3600));
        delete obj.percent;
        obj.url = LanguageSiteMap(obj.name.toLowerCase());
        obj.image = ImageMap(obj.name);
    });

    // FILTER : Hours & Language
    data = data.filter(obj => obj.hours > filter_hours);
    data = data.filter(obj => !exclude_filter.includes(obj.name));

    // COMBINE : Similar Languages
    data = CombineStats(data);

    // SORT : Hours (Highest -> Lowest)
    data.sort((a, b) => { return b.hours - a.hours; });

    return data;
}

// -- [ MAP Language -> Docs ] --
function LanguageSiteMap(lang) {
    // Check if the language is in the map, if not, use a default URL
    return languageMap[lang] || "https://devdocs.io/" + lang;
}

// -- [ MAP Language -> Icon ] --
function ImageMap(lang) {
    const languageMappings = {
        "C#": "csharp",
        "C++": "cplusplus",
        "CSS": "css3",
        "HTML": "html5",
        "SQL": "mysql",
        "Vue.js": "vuejs",
        "Vue": "vuejs",
    };

    // Convert the input language to lowercase using the mapping or to lowercase directly
    return languageMappings[lang] || lang?.toLowerCase();
}

// -- [ Combine Statistics ] --
function CombineStats(data) {
    // Combine the "hours" of languages that are in the combine_list & remove the languages that were combined
    combine_list.forEach((combine) => {
        let combined_hours = 0;
        let combined_index = 0;
        
        const lang1 = combine[0];
        const lang2 = combine[1];
        const final_name = combine[2];

        // Combine lang1 & lang2 hours and remove lang1 & lang2 from data and replace with final_name
        data.forEach((obj, index) => {
            if (obj.name === lang1 || obj.name === lang2) {
                combined_hours += obj.hours;
                combined_index = index;
            }
        });

        // Remove lang1 & lang2 from data
        data.map((obj, index) => {
            if (index === combined_index) {
                obj.name = final_name;
                obj.hours = combined_hours;
            }
            else if (obj.name === lang1 || obj.name === lang2) {
                data.splice(index, 1);
            }
        });

        // Add combined language to data
        data.push({
            name: final_name,
            hours: combined_hours,
            url: LanguageSiteMap(final_name.toLowerCase()),
            image: ImageMap(final_name),
        });
    });

    return data;
}