import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

// Show toast
const $toast = useToast();
let instance = null;

export function ShowToast(Message, Type = 'info', duration = 3000) {
    instance = $toast.open({
        message: Message,
        type: Type,
        position: 'bottom-right',
        duration: duration,
        dismissible: true,
        pauseOnHover: true,
        closeOnClick: true,
        animate: {
            enterClass: 'animate__animated animate__fadeInRight',
            leaveClass: 'animate__animated animate__fadeOutRight'
        }
    });
    return instance;
}