
import axios from 'axios';
import { GetGitHubLink, GetAPILink } from './variables.js';
import { ImageMap, LanguageSiteMap, exclude_filter } from './wakatime.js';

export { GetGitProjects, GetWorkExperience, ParseDate };


const GithubAPI = GetGitHubLink();
const API = GetAPILink() + 'notion/';
const exclusion = ["NotAddison"];

async function GetGitProjects() {
    const res = await axios.get(GithubAPI);
    let data = res.data

    // Filter out forked repos from data
    data = data.filter((repo) => {
        if (repo.fork === false && !exclusion.includes(repo.name)) return true;
        return false;
    });

    // [OPTIONAL] : Get list of languages from Github API (repo.languages_url)

    // Add image to repo.language
    data.forEach((repo) => {
        repo.language_image = ImageMap(repo.language);
    });

    return data;
}

async function GetWorkExperience() {
    const res = await axios.get(API + "19c122bc62d4472c88782c9ac0be9165");
    let data = res.data

    // Sort by data order (highest to lowest)
    data.sort((a, b) => { return b.Order.value - a.Order.value; });

    // Parse Date
    data.forEach((element) => {
        const start = ParseDate(element.Duration.start);
        const end = ParseDate(element.Duration.end);
        element.Duration.start = start;
        element.Duration.end = end;
    });

    return data;
}

function ParseDate(date) {
    if (date == null) return;
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const date_split = date.split("-");
    return `${months[parseInt(date_split[1]) - 1]} ${date_split[0]}`;
}