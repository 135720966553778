<!-- [ TEMPLATE ] -->
<template lang="en">
    <div style="width:100%">
        <h2>Code Statistics</h2>
        <div class="indent">
            <p>Total Hours : <span class="highlight">{{ hours }}</span></p>
            <p class="subtle mobile-hidden">Graph represents languages with more than <span class="highlight">{{ filter_hours }}</span> hours.</p>
        </div>

        <!-- ApexChart -->
        <div class="container">
            <div class="half mobile-hidden">
                <apexchart width="100%" height="400" type="bar" :options="options" :series="series"></apexchart>
            </div>

            <!-- Language List -->
            <div class="stat-container half" style="max-height:40vh">
                <section  v-for="item in wakaData" :key="item.name">
                    <a v-glitch="glitchOption" class="language-container hover" :href="item.url" target="_blank" v-tooltip="item.name">
                        <i :class="`devicon-${item.image}-plain`" class="language-icon"></i>
                    </a>
                </section>
            </div>
        </div>
    </div>
</template>

<!-- [ SCRIPT ] -->
<script>
import { GetWakaData } from "../functions/wakatime.js";
import { GetGlitchSettings } from "@/functions/variables";

export default {
    data() {
        return {
            hours: "---",
            filter_hours: "---",
            wakaData: [],

            options: {
                chart: {
                    id: "code-stats",
                    toolbar: {
                        show: false,
                    },
                },
                xaxis: {
                    labels: {
                        formatter: function (val) {
                            return val + " hours";
                        },
                        style: {
                            colors: "#fff",
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: "#fff",
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                    formatter: function (val) {
                        return val + " hours";
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        horizontal: true,
                    },
                },
                grid: {
                    show: false,
                },
                // tooltip text color black
                tooltip: {
                    theme: "dark",
                    // Add hours
                    y: {
                        formatter: function (val) {
                            return val + " hours";
                        },
                    },
                },
            },

            series: [
                {
                    name: "Stats",
                    data: [],
                },
            ],
        };
    },

    created() {
        GetWakaData()
            .then((data) => {
                this.hours = data.readable_hours;
                this.filter_hours = data.filter_hours;
                this.wakaData = data.data;

                // ApexChart Series
                let series = [
                    {
                        data: this.wakaData.map((item) => ({
                            x: item.name,
                            y: Math.round(item.hours),
                            fillColor: item.color,
                        })),
                    },
                ];

                // Update Chart Data
                this.series = series;
            })
            .catch((err) => console.log(err));

        this.glitchOption = GetGlitchSettings();
    },
};
</script>

<!-- [ STYLING ] -->
<style>
.highlight {
    color: #1f73d3;
    font-weight: bold;
}

.stat-container {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: flex-start;

    width: 100%;
    height: 100%;
    gap: 3rem;
    overflow: scroll;
}

.language-container {
    display: flex;
    width: 5rem;
    height: 5rem;

    justify-content: center;
    align-items: center;
    text-align: center;
    justify-items: center;

    overflow: hidden;

    border-radius: 0.5rem;
    background-color: rgba(0, 0, 0, 0.445);
}

.language-icon {
    font-size: 3rem;
}

/* Mobile */
@media (max-width: 768px) {
    .stat-container {
        justify-content: center;
        gap: 1rem;
    }
}
</style>
