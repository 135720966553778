import { GetAPILink } from './variables.js';
import axios from 'axios';

export { GetEducation, GetAwards }

const api = GetAPILink() + 'notion/';
const db_education = "0b2963f7c9c44af0863f761e8c114cf3"
const db_awards = "4d57c102264642d8aa656d6f5952ec1b"

async function GetEducation() {
    try {
        const res = await axios.get(api + db_education);
        const data = res.data;
        data.sort((a, b) => (a.Order.value > b.Order.value) ? 1 : -1)

        return data;
    } catch (error) { console.log("ERROR:", error) }
}

async function GetAwards() {
    try {
        const res = await axios.get(api + db_awards);
        const data = res.data;
        data.sort((a, b) => (a.Order.value > b.Order.value) ? 1 : -1)

        return data;
    } catch (error) { console.log("ERROR:", error) }
}