// Export Functions
export { GetGlitchSettings, GetAPILink, GetGitHubLink };

// --- [ API LINK ] --- //
function GetAPILink() {
  return process.env.VUE_APP_API_LINK
}

// --- [ GITHUB API LINK ] --- //
function GetGitHubLink(username = "NotAddison") {
  return `https://api.github.com/users/${username}/repos`
}

// --- [ Glitch Settings ] --- //
function GetGlitchSettings() {
  return {
    "playMode": "hover",
    "createContainers": true,
    "hideOverflow": true,
    "timing": {
      "duration": 500,
      // "easing": "ease-in-out"
    },
    "glitchTimeSpan": {
      "start": 0.1,
      "end": 0.7
    },
    "shake": {
      "velocity": 5,
      "amplitudeX": 0.05,
      "amplitudeY": 0.05
    },
    "slice": {
      "count": 6,
      "velocity": 15,
      "minHeight": 0.02,
      "maxHeight": 0.15,
      "hueRotate": true
    },
    "pulse": false
  }
}
